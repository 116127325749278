<template>
  <div>
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-cadastro">
        Cadastro Geral
        <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-fabricantes">
        Fabricantes
        <i :class="$utilidade.getIcone('fabricante') + ' fa-2x'"></i>
      </v-tab>
      <!-- <v-tab href="#tab-risco">
        Riscos Sugeridos
        <i :class="$utilidade.getIcone('risco') + ' fa-2x'"></i>
      </v-tab> -->
    </v-tabs>

    <ed-form
      :formData="formData"
      :handlerSave="salvar"
      v-if="!this.$root.$session.loadingContent"
    >
      <cadastro-geral
        :formData="formData"
        v-show="strTab == 'tab-cadastro'"
        :key="'cadastro-' + $root.$session.versao"
      />

      <cadastro-fabricantes
        :formData="formData"
        v-show="strTab == 'tab-fabricantes'"
        :key="'ca-' + $root.$session.versao"
      />
    </ed-form>
  </div>
</template>

<script>
import { EdForm } from "@/components/common/form";

import CadastroGeral from "./partials/geral.vue";
import CadastroFabricantes from "./partials/fabricantes.vue";

export default {
  name: "pageCadastroFuncao",
  props: {
    intEpiId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    intTipoEquipamentoId: {
      type: [Number, String],
      required: true,
      default: null,
    },
  },
  components: {
    EdForm,
    CadastroGeral,
    CadastroFabricantes,
  },
  mounted() {
    this.getEpi();
  },
  created() {},
  data() {
    return {
      strTab: "tab-cadastro",
      boolCarregado: false,
      formData: {
        intId: 0,
        intTipoEquipamentoId: this.intTipoEquipamentoId,
        strNome: null,
        boolAtivo: 1,
        cas: [],
      },
    };
  },
  watch: {
    intCidId() {
      this.getEpi();
    },
  },
  methods: {
    getEpi() {
      if (!this.intEpiId) {
        this.boolCarregado = true;
        return;
      }

      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Cadastro/Equipamento", { intId: this.intEpiId })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            this.formData = Object.assign(objResponse.result, {});
          }

          this.boolCarregado = true;
        });
    },

    salvar() {
      this.$root.$request
        .post("Cadastro/Equipamento", this.formData)
        .then((objResponse) => {
          if (objResponse.status == 200) {
            if (this.$route.name.indexOf("edit") == -1) {
              this.$router.push({
                name: this.$route.name.replace("register", "edit"),
                params: { intId: objResponse.result.intId },
              });
            } else {
              this.getEpi();
            }
          }
        });
    },
  },
};
</script>
