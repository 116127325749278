<template>
  <div class="row ma-0 pa-0">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
      <span class="form-title">Dados cadastrais</span>

      <div class="row">
        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.strNome"
          name="strNome"
          label="Nome"
          rules="required"
          :disabled="!formData.boolAtivo"
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7"
          v-model="formData.intPrazoReposicao"
          name="intPrazoReposicao"
          label="Prazo para Reposição"
          type="number"
          :disabled="!formData.boolAtivo"
        />

        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5"
          v-model="formData.strTipoPrazoReposicao"
          name="strTipoPrazoReposicao"
          label="Tipo de prazo"
          :items="[
            { intId: 'day', strNome: 'em dias' },
            { intId: 'month', strNome: 'em meses' },
          ]"
          :disabled="!formData.boolAtivo"
          :rules="formData.intPrazoReposicao ? 'required' : ''"
        />

        <ed-input-text-area
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.strEspecificacao"
          name="strEspecificacao"
          label="Especificações"
          maxlength="3000"
          :disabled="!formData.boolAtivo"
        />

        <ed-input-text-area
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.strHigienizacao"
          name="strHigienizacao"
          label="Higienização"
          required
          maxlength="3000"
          :disabled="!formData.boolAtivo"
        />

        <ed-input-text-area
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.strConservacao"
          name="strConservacao"
          label="Conservação"
          required
          maxlength="3000"
          :disabled="!formData.boolAtivo"
        />

        <ed-input-text-area
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.strObservacao"
          name="strObservacao"
          label="Observacoes"
          required
          maxlength="3000"
          :disabled="!formData.boolAtivo"
        />

        <ed-input-switch
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.boolAtivo"
          name="boolAtivo"
          label="EPI ativo?"
          required
          rules="required"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdInputText,
  EdInputTextArea,
  EdInputSelect,
  EdInputSwitch,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    intFuncaoId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
    EdInputText,
    EdInputSelect,
    EdInputTextArea,
    EdInputSwitch,
  },
  mounted() {},
  created() {},
  data() {
    return {};
  },
  watch: {},
  methods: {},
};
</script>
