<template>
  <div class="row mb-3">
    <ed-modal v-model="boolExibirCadastro" width="60%">
      <div slot="title">
        {{ formDataCadastro.intId ? "Editar Fabricante" : "Inserir novo fabricante" }}
      </div>

      <div class="" slot="content">
        <ed-form :formData="formDataCadastro" :handlerSave="salvar">
          <div class="row pa-0 ma-0">
            <ed-input-auto-complete
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0"
              v-model="formDataCadastro.intEpiId"
              label="Pesquisar pelo EPI ou Número do CA"
              name="intEpiId"
              itemText="strNomeEpi"
              route="System/Epi"
              :initializeItems="false"
              @selected="setEpi"
            />

            <ed-input-auto-complete
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0"
              v-model="formDataCadastro.intCadastroGeralFabricanteId"
              label="Selecione o Fabricante"
              name="intCadastroGeralId"
              route="Cadastro/CadastroGeral"
              :filters="{
                intTipoCadastroGeralId: 1339,
              }"
              :initializeItems="false"
              :itemList="formDataCadastro.fabricante ? [formDataCadastro.fabricante] : []"
              @selected="
                (x) => {
                  formDataCadastro.fabricante = x;
                }
              "
            />
            <ed-input-text
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pt-0"
              v-model="formDataCadastro.strNumeroCa"
              name="strNumeroCa"
              label="Número CA"
              rules="required"
            />

            <ed-input-text
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pt-0"
              v-model="formDataCadastro.strNumeroLote"
              name="strNumeroLote"
              label="Número do Lote"
            />

            <ed-input-money
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pt-0"
              v-model="formDataCadastro.floatValorCusto"
              name="floatValorCusto"
              label="Valor de Custo"
            />

            <ed-input-date
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0"
              v-model="formDataCadastro.strDataFabricacao"
              name="strDataFabricacao"
              label="Data de Fabricação"
            />

            <ed-input-date
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0"
              v-model="formDataCadastro.strDataValidade"
              name="strDataValidade"
              label="Data de Validade"
            />

            <ed-input-text-area
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-0"
              v-model="formDataCadastro.strDescricao"
              name="strDescricao"
              label="Descrição"
            />

            <ed-alert class="text-left" type="warning" v-if="getValidadeCa() < 0">
              Esse CA está vencido a {{ getValidadeCa() }} dia(s)
            </ed-alert>
          </div>
        </ed-form>
      </div>
    </ed-modal>

    <ed-table
    :key="$root.$session.versao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :loading="loading"
      @register="onRegister"
      @edit="onEdit"
      @delete="onDelete"
      :config="{ registerName: 'Inserir novo CA' }"
      disabledSearchRows
    />
  </div>
</template>

<script>
import {
  EdForm,
  EdTable,
  EdModal,
  EdAlert,
  EdButton,
  EdInputText,
  EdInputTextArea,
  EdInputSelect,
  EdInputMoney,
  EdInputCheckbox,
  EdInputDate,
  EdInputAutoComplete,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdForm,
    EdTable,
    EdModal,
    EdAlert,
    EdButton,
    EdInputText,
    EdInputTextArea,
    EdInputSelect,
    EdInputCheckbox,
    EdInputMoney,
    EdInputDate,
    EdInputAutoComplete,
  },
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      loading: false,
      boolExibirCadastro: false,
      arrayHeaders: [
        {
          text: "Fabricante",
          sortable: true,
          value: "fabricante",
        },
        {
          text: "Custo",
          sortable: true,
          value: "floatValorCusto",
        },
        {
          text: "Lote",
          sortable: true,
          value: "strNumeroLote",
        },
        {
          text: "CA",
          sortable: true,
          value: "strNumeroCa",
        },
        // {
        //   text: "Dt. Fabricação",
        //   sortable: true,
        //   value: "strDataFabricacao",
        // },
        {
          text: "Dt. Validade",
          sortable: true,
          value: "strDataValidade",
        },
      ],
      arrayRows: [],
      formDataCadastro: {
        intId: 0,
        intCadastroGeralFabricanteId: null,
        intEpiId: null,
        fabricante: null,
        strNumeroCa: null,
        strNumeroLote: null,
        floatValorCusto: null,
        strDataFabricacao: null,
        strDataValidade: null,
      },
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    initialize() {
      if (!this.formData.fabricantes) {
        this.formData.fabricantes = [];
      }

      let arrayRows = [];
      this.formData.fabricantes.forEach((objEquipamentoFabricante) => {
        let item = {
          intId: objEquipamentoFabricante.intId,
          fabricante: objEquipamentoFabricante.fabricante
            ? objEquipamentoFabricante.fabricante.strNome
            : "---",
          strNumeroCa: objEquipamentoFabricante.strNumeroCa,
          strNumeroLote: objEquipamentoFabricante.strNumeroLote,
          floatValorCusto: objEquipamentoFabricante.floatValorCusto
            ? this.$utilidade.floatToMoney(objEquipamentoFabricante.floatValorCusto)
            : "---",
          strDataFabricacao: objEquipamentoFabricante.strDataFabricacao
            ? this.$utilidade.toDate(
                this.$utilidade.convertDate(
                  objEquipamentoFabricante.strDataFabricacao,
                  "YYYY-MM-DD"
                ),
                true
              )
            : "---",
          strDataValidade: objEquipamentoFabricante.strDataValidade
            ? this.$utilidade.toDate(
                this.$utilidade.convertDate(
                  objEquipamentoFabricante.strDataValidade,
                  "YYYY-MM-DD"
                ),
                true
              )
            : "---",
          _item: objEquipamentoFabricante,
        };

        arrayRows.push(item);
      });

      this.arrayRows = arrayRows;
    },

    getValidadeCa() {
      let intDiferencaDias = 0;

      if (this.formDataCadastro.strDataValidade) {
        intDiferencaDias = this.$utilidade.diffDays(
          this.formDataCadastro.strDataValidade,
          this.$utilidade.toDate(null, true)
        );
      }

      return Number(intDiferencaDias);
    },

    setEpi(obj) {
      this.formDataCadastro.epi = obj;

      if (obj) {
        this.formDataCadastro.fabricante = {
          intId: 0,
          strNome: obj.strNomeFabricante,
          strCnpj: obj.strCnpjFabricante,
        };
        this.formDataCadastro.intCadastroGeralFabricanteId = 0;
        this.formDataCadastro.strNumeroCa = obj.strNumeroCa;
        this.formDataCadastro.strDataValidade = obj.strDataValidade;
        this.formDataCadastro.strNumeroCa = obj.strNumeroCa;
        this.formDataCadastro.strDescricao = obj.strDescricaoEpi;
      }
    },

    onRegister() {
      this.boolExibirCadastro = !this.boolExibirCadastro;

      this.formDataCadastro = {
        intId: 0,
        intEquipamentoId: this.formData.intId,
        intEpiId: null,
        intCadastroGeralFabricanteId: null,
        strDescricao: null,
        boolPesquisarEpi: false,
        fabricante: null,
        strNumeroCa: null,
        strNumeroLote: null,
        floatValorCusto: null,
        strDataFabricacao: null,
        strDataValidade: null,
      };
    },

    onEdit(data) {
      this.formDataCadastro = Object.assign(data, {});
      this.boolExibirCadastro = !this.boolExibirCadastro;
    },

    onDelete(data) {
      let self = this;

      data.forEach((intId) => {
        let indexCadastroEmail = self.getIndexByIntId(intId);

        if (indexCadastroEmail != -1) {
          self.formData.fabricantes.splice(indexCadastroEmail, 1);
        }
      });

      this.initialize();
    },

    getIndexByIntId(intId) {
      return this.formData.fabricantes.findIndex((c) => c.intId == intId);
    },

    salvar() {
      let intIndexCadastroGeralEmail = this.getIndexByIntId(this.formDataCadastro.intId);

      if (intIndexCadastroGeralEmail != -1) {
        this.formData.fabricantes[intIndexCadastroGeralEmail] = Object.assign(
          this.formDataCadastro,
          {}
        );
      } else {
        //VERIFICAR SE O CA EXISTE
        let intIndex = this.formData.fabricantes.findIndex(
          (c) =>
            c.intId != this.formDataCadastro.intId &&
            c.strNumeroCa == this.formDataCadastro.strNumeroCa
        );

        if (intIndex != -1) {
          this.$root.$dialog.error("O CA informado já está cadastrado para esse EPI");
          return;
        }

        this.formData.fabricantes.push({
          intId: (this.formData.fabricantes.length + 1) * -1,
          intEquipamentoId: this.formData.intId,
          intEpiId: this.formDataCadastro.intEpiId,
          intCadastroGeralFabricanteId: this.formDataCadastro
            .intCadastroGeralFabricanteId,
          fabricante: this.formDataCadastro.fabricante,
          strNumeroCa: this.formDataCadastro.strNumeroCa,
          strNumeroLote: this.formDataCadastro.strNumeroLote,
          floatValorCusto: this.formDataCadastro.floatValorCusto,
          strDataFabricacao: this.formDataCadastro.strDataFabricacao,
          strDataValidade: this.formDataCadastro.strDataValidade,
          boolPrincipal: 0,
        });
      }

      this.initialize();
      this.boolExibirCadastro = !this.boolExibirCadastro;
    },
  },
};
</script>
